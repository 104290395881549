export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token?.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const authVerify = (accessToken) => {
  if (accessToken) {
    const decodedJwt = parseJwt(accessToken);
    if (decodedJwt?.exp * 1000 < Date.now()) {
      //  console.log("token expired");
      return false;
    }
    //  console.log("token validate");
    return true;
  }
  return false;
};
